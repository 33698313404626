import Image from 'next/image';
import Link from 'next/link';
import { FaChevronRight, FaLightbulb, FaTools, FaPlug } from 'react-icons/fa';
import Layout from '@components/common/Layout';

const links = [
  {
    title: 'Services',
    description: 'View the services we offer here at HLB Electric',
    icon: FaTools,
    url: '/electrical-services',
  },
  {
    title: 'Lighting',
    description: 'View lighting installations completed by us!',
    icon: FaLightbulb,
    url: '/projects/lighting',
  },
  {
    title: 'Outlets & Light Switches',
    description: 'View outlets and light switches installed by HLB Electric',
    icon: FaPlug,
    url: '/projects/outlets-and-light-switches',
  },
];

const Custom404 = () => {
  return (
    <div>
      <main className="mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="shrink-0 pt-16">
          <div className="flex justify-center">
            <Image
              src="/images/logos/hlb-electric-logo-small.svg"
              alt="HLB Electric logo"
              width={53}
              height={48}
            />
          </div>
        </div>
        <div className="mx-auto max-w-xl py-12">
          <div className="text-center">
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              This page does not exist.
            </h1>
            <p className="mt-2 text-lg text-gray-500">
              The page you are looking for could not be found.
            </p>
          </div>
          <div className="mt-12">
            <h2 className="text-sm font-semibold uppercase tracking-wide text-gray-500">
              Popular pages
            </h2>
            <ul
              role="list"
              className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200"
            >
              {links.map((link, linkIdx) => (
                <li
                  key={linkIdx}
                  className="relative flex items-start space-x-4 py-6"
                >
                  <div className="shrink-0">
                    <span className="flex h-12 w-12 items-center justify-center rounded-lg bg-indigo-50">
                      <link.icon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <h3 className="text-base font-medium text-gray-900">
                      <span className="rounded-sm focus-within:ring-2 focus-within:ring-red-600 focus-within:ring-offset-2">
                        <a href={link.url} className="focus:outline-none">
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          {link.title}
                        </a>
                      </span>
                    </h3>
                    <p className="text-base text-gray-500">
                      {link.description}
                    </p>
                  </div>
                  <div className="shrink-0 self-center">
                    <FaChevronRight
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </li>
              ))}
            </ul>
            <div className="mt-8">
              <Link
                href="/"
                prefetch={false}
                className="text-base font-medium text-red-600 hover:text-red-700"
              >
                Or go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

Custom404.Layout = Layout;

export default Custom404;
